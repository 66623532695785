import React, { createContext, useMemo, useCallback, useState } from "react"

export const ModalContext = createContext()

const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState(null)
  const [isClosed, setIsClosed] = useState(null)

  const openModal = useCallback(
    data => {
      setModal(data)
      setIsClosed(false)
    },
    [setModal, setIsClosed],
  )

  const closeModal = useCallback(() => {
    setModal(null)
    setIsClosed(true)
  }, [setModal, setIsClosed])

  const value = useMemo(
    () => ({ modal, openModal, closeModal, isClosed }),
    [modal, openModal, closeModal, isClosed],
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContextProvider

export { ModalContextProvider }
